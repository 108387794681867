<template>
  <div class="container-fluid bg-light">
    <!-- REG HORIZON -->
    <div class="row text-white bg-kpmg-dark-blue py-4 mb-4">
      <div class="col">
        <h1 class="kstyle">Contact</h1>
      </div>
    </div>
    <b-row>
      <b-col>
        <b-container>
    <div class="my-4">
      For any queries including commercial model and technical support contact:
      <a href="https://home.kpmg/uk/en/home/contacts/o/lucas-ocelewicz.html" target="_blank">Lucas Ocelewicz</a> or
      <a href="https://home.kpmg/xx/en/home/contacts/d/kate-dawson.html" target="_blank">Kate Dawson</a>
    </div>
        </b-container>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  created: function () {
    this.$stat.log({ page: 'contact', action: 'open contact' })
  }
}
</script>
